import * as React from "react"

import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"

const Alternatives = () => (
  <Layout>
    <Seo title="Alternatives" />
    <h1>Alternative IVIG Dosing Calculators</h1>
    <p>Please find alternative IVIG Dosing Calculators below:</p>
    <ul>
      <li>
        <a href="https://www.albertahealthservices.ca/webapps/labservices/IVIG_Dosing_Calculator.htm">
          Alberta Health Services IVIG Dosing Calculator
        </a>
      </li>
      <li>
        <a href="https://www.pbco.ca/IVIG_Dosing_Calculator.htm">
          British Columbia Provincial Health Services Authority IVIG Dosing
          Calculator
        </a>
      </li>
      <li>
        <a href="http://www.nshealth.ca/IVIG/">
          Nova Scotia Health Authority IVIG Dosing Calculator
        </a>
      </li>
    </ul>
    <div className="link-home">
      <Link to="/">Go back to status page</Link>
    </div>
  </Layout>
)

export default Alternatives
